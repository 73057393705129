:root, html[data-theme='light'] {
  --max-width: 1900px;
  --border-radius: 12px;
  --font-mono: ui-monospace, Menlo, Monaco, 'Cascadia Mono', 'Segoe UI Mono',
    'Roboto Mono', 'Oxygen Mono', 'Ubuntu Monospace', 'Source Code Pro',
    'Fira Mono', 'Droid Sans Mono', 'Courier New', monospace;
  --font-roboto: "Roboto", "Helvetica", "Arial", sans-serif;
  --font-system: "Liberation Serif", "Times New Roman", Times, serif;

  --foreground-rgb: 0, 0, 0;
  --background-start-rgb: 214, 219, 220;
  --background-end-rgb: 255, 255, 255;

  --background-light: #FFF;
  --background-dark: #ffffff10;
  --background-color: #FFF;

  --primary-chip-bg: rgba(0, 0, 0, 0.08);
  --primary-chip-color: rgba(0, 0, 0, 0.87);
  --primary-chip-border: rgba(0, 0, 0, 0.12);

  --input-background: #FAFAFA;

  --hover-menu-bg: #f5f5f5;

  --primary-glow: conic-gradient(
    from 180deg at 50% 50%,
    #16abff33 0deg,
    #0885ff33 55deg,
    #54d6ff33 120deg,
    #0071ff33 160deg,
    transparent 360deg
  );
  --secondary-glow: radial-gradient(
    rgba(255, 255, 255, 1),
    rgba(255, 255, 255, 0)
  );

  --tile-start-rgb: 239, 245, 249;
  --tile-end-rgb: 228, 232, 233;
  --tile-border: conic-gradient(
    #00000080,
    #00000040,
    #00000030,
    #00000020,
    #00000010,
    #00000010,
    #00000080
  );

  --callout-rgb: 238, 240, 241;
  --callout-border-rgb: 172, 175, 176;
  --card-rgb: 180, 185, 188;
  --card-border-rgb: 131, 134, 135;

  /* ARON CUSTOM */
  --primary-font-rgb: rgb(75,81,92);
  --secondary-font-rgb: rgb(199,141,63);

  --default-color: #C68E3F;
  --primary-color: #313131;
  --secondary-color: #4E54C8;
  --danger-color: #FF5722;

  --faint-border-color: #eaecef;
  --default-box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;

  ::selection {
    /* WebKit/Blink Browsers */
    /* background: rgba(224, 64, 251, 0.5); */
    background: #b5d6fe;
  }
  ::-moz-selection {
    /* Gecko Browsers */
    /* background: rgba(224, 64, 251, 0.5); */
    background: #b5d6fe;
  }

  --quill-font-size: 1.1rem;
  --quill-line-height: 1.8rem;

  /**
   * Translation Editor
  */
  --translation-editor-bg: #FAFAFA;

  --green-ready: #4CAF50;
  --red-not-ready: #F44336;
  --neon-hover-color: #C68E3F;
  --neon-opposite-color: #313131;
}

html[data-theme='dark'] {
  --primary-color: #e1e0e1;
  --secondary-color: #648dfe;
  --background-color: #0d1114;
  --primary-font-rgb: #e1e0e1;
  --secondary-font-rgb: #648dfe;
  --faint-border-color: #26282a;

  --primary-chip-bg: #2b3237;
  --primary-chip-color: #e1e0e1;
  --primary-chip-border: rgba(0, 0, 0, 0.12);

  --input-background: #293236;
  --hover-menu-bg: #141414;

  /**
   * Translation Editor
  */
  --translation-editor-bg: #293236;

  --green-ready: #4CAF50;
  --red-not-ready: #F44336;
  --neon-hover-color: #0CF25D;
  --neon-opposite-color: #648dfe;
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

html,
body {
  // overflow-x: hidden;
  /*
   * ! DO NOT SET THE BACKGROUND COLOR HERE - SET IT IN THE LAYOUT HEADER
   */
  /* background: var(--secondary-color); */
  /* background: #FFF; */
  min-height: 100vh;
  margin: 0;
  font-family: var(--font-system);
}

body {
  color: rgb(var(--foreground-rgb));
  /*
   * ! DO NOT SET THE BACKGROUND COLOR HERE - SET IT IN THE LAYOUT HEADER
   */
  /* background: #FFF; */
  /* background: var(--secondary-color); */
  /* background: #FFF; */
  display: flex;
  flex-direction: column;
}

a {
  color: inherit;
  text-decoration: none;
}

#__next {
  height: 100%;
}

.ql-toolbar.ql-snow {
  border: none !important;
  border-bottom: 1px solid var(--faint-border-color) !important;
}

.ql-container.ql-snow {
  border: none !important;
  // padding: 2rem 0px;
  padding-top: 1rem;
}

.ql-editor.ql-blank {
  min-height: 150px;
}

.ql-picker-label.ql-active {
  color: var(--secondary-font-rgb) !important;
}

.rtl > .ql-container > .ql-editor {
  direction: rtl;
  text-align: right;
}

.ql-editor {
  font-family: var(--font-system);
  font-size: var(--quill-font-size);
  line-height: var(--quill-line-height) !important;
  padding: 0px !important;

  p {
    font-size: var(--quill-font-size) !important;
    line-height: var(--quill-line-height) !important;
  }

  a {
    color: var(--secondary-font-rgb) !important;
    text-decoration: none !important;
    cursor: pointer !important;
  }

  a:hover {
    text-decoration: underline !important;
  }

  h3 {
    font-size: calc(var(--quill-font-size) + 1rem) !important;
    padding-bottom: 0.9rem !important;
    line-height: 2rem !important;
  }

  h4 {
    font-size: calc(var(--quill-font-size) + 0.5rem) !important;
    padding-bottom: 0.9rem !important;
  }

  .ql-blank {
    min-height: 300px !important;
  }

  blockquote {
    font-style: italic;
    border-left: 1px solid var(--secondary-font-rgb) !important;
    font-size: var(--quill-font-size) !important;
    padding-top: 0.7rem !important;
    padding-bottom: 0.7rem !important;
  }
}

.ql-fill {
  fill: var(--primary-font-rgb) !important;
}

.ql-clipboard {
  position: fixed !important;
  left: 50% !important;
  top: 50% !important;
  display: none;
}

.fixed-toolbar {
  position: sticky;
  top: 0;
  width: 100%;
  z-index: 1000;
  background: #FFF;
}

.ql-formats {
  margin-bottom: 0.5rem;
  margin-top: 0.5rem;
}

#scrolling-container {
  // height: 100%;
  // min-height: 100%;
  // overflow-y: scroll;
}

.quill {
  position: relative;
}

div.quill > div.ql-toolbar.ql-snow {
  background-color: var(--background-color) !important;
  position: fixed !important;
  top: 79px !important;
  left: 0 !important;
  z-index: 9999 !important;
  text-align: center;
}

.ql-formats:nth-child(1) {
  margin-left: -275px;
}

@media screen and (max-width: 450px) {
  .fixed-toolbar {
    width: 100%;
  }
}

@media screen and (max-width: 800px) {
  .ql-formats:nth-child(1) {
    margin-left: 0px;
  }
}
