.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  background: #FAFAFA;
  min-height: 100vh;
  color: var(--primary-color);

  h1 {
    font-size: 2.5rem;
    margin-bottom: 20px;
  }

  p {
    font-size: 1.5rem;
    margin-bottom: 20px;
  }
}

.messageBox {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  border-radius: 5px;
  background: #fff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  text-align: center;
  max-width: 500px;
  width: 100%;
}

.link {
  color: var(--secondary-font-rgb);
  text-decoration: none;
  font-weight: bold;
  cursor: pointer;
}

.goBackSection {
  text-align: center;
}
